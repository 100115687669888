import React from 'react';
import styled from "styled-components";

import Layout from '../components/theme/layout';
import Seo from '../components/seo';

const QiwioPink = '#ff5679'

const HeroContainer = styled.div`
    padding: 30px;

    @media screen and (min-width: 501px) {
        text-align: center;
        padding: 50px;
    }

    h1 {
        font-size: 1.625rem;
        font-family: 'Barlow Semi Condensed';
        font-weight: 700;
        margin-bottom: 30px;

        @media screen and (min-width: 501px) {
            font-size: 2.5rem;
        }
    }

    p {
        font-family: 'barlow';
        font-size: 1rem;
        font-weight: 400;

        @media screen and (min-width: 501px) {
            font-size: 1.25rem;
            max-width: 80%;
            margin: 0 auto;
        }
         @media screen and (min-width: 1200px) {
            max-width: 60%;
        }
    }

    a {
        color: ${QiwioPink};
    }
`
const SalesContactFormWrapper = styled.div`
    padding: 0 30px 100px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

     @media screen and (min-width: 900px) {
        padding: 0 30px 100px 50px;
     }

    input,
    select,
    textarea {
        width: 100%;
        background-color: #272731;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 10px;
        color: white;

         @media screen and (min-width: 1100px) {
            width: 49.5%;

            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(7) {
                margin-right: 1%;
            }

            &:nth-child(10) {
                width: 100%;
            }
        }

        &::placeholder {
            font-family: 'Barlow Semi Condensed';
            font-size: 1.125rem;
            font-weight: 600;
            color: rgba(255, 255, 255, .6);
        }

         &:focus {
            outline: 1px solid #ff5679;
        }
    }

    textarea {
         @media screen and (min-width: 900px) {
            width: 100%;
        }
    }

    label {
        font-family: 'Barlow Semi Condensed';
        color: white;
        display: block;
        margin-bottom: 10px;
    }

    button {
        background-color: ${QiwioPink};
        border: 1px solid ${QiwioPink};
        color: white;
        margin-top: 10px;
        width: 100%;
        padding: 12px;
        border-radius: 4px;
        font-size: 1rem;
        font-family: 'Barlow Semi Condensed';
        font-weight: 600;
        color: white;
        cursor: pointer;
        transition: all .4s;

        &:hover {
            transform: translateY(-4px)
        }
    }
`

export default function ContactSupportPage() {
  return (
      <Layout>
        <Seo title='Contact support' />
        <HeroContainer>
            <h1>How can we support you today?</h1>
            <p>For instant help, head over to our <a href="https://qiwioab.zendesk.com/hc/en-us" target='_blank' rel="noreferrer">help center</a> to find in depth guides to help you out! Otherwise, submit a support ticket using the form below!</p>
        </HeroContainer>

        <SalesContactFormWrapper>
            <form method='post' netlify-honeypot='bot-field' data-netlify='true' name='support contact form' enctype="multipart/form-data">
                <input type="hidden" name='bot-field' />
                <input type="hidden" name='form-name' value='support contact form' />

                <input type="text" name="firstName" id="firstName" placeholder='First name *' required/>
                <input type="text" name="lastName" id="lastName" placeholder='Last name *' required/>
                <input type="email" name="email" id="email" placeholder='Your business email *' required/>
                <input type="tel" name="phone" id="phone" placeholder='Phone number *' required/>
                <input type="text" name="qiwio-account-name" id="qiwio-account-name" placeholder='Qiwio account name *' required/>
                <input type="url" name="url" id="url" placeholder='URL where we can see this issue'/>
                <label htmlFor="files">Upload a screenshot (.jpg, .png, .pdf)</label>
                <input type="file" name="files" id="files" accept='image/jpg, image/jpeg, .pdf, image/png' multiple/>
                <textarea name="message" id="message" cols="30" rows="5" placeholder='How can our support team help you today? *' required></textarea>
                <button>Submit support ticket</button>
            </form>
        </SalesContactFormWrapper>

      </Layout>
  )
}
